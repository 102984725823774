import { useGetIsFeatureEnabledQuery } from "@/common/services/feature-flags.api";
import { Loader2 } from "lucide-react";
import React from "react";
import { Navigate } from "react-router-dom";

interface FeatureGateProps {
	featureName: string;
	children: React.ReactNode;
	fallback?: React.ReactNode;
	redirectTo?: string;
}

export const FeatureGate: React.FC<FeatureGateProps> = ({
	featureName,
	children,
	fallback,
	redirectTo,
}) => {
	const { data, isLoading } = useGetIsFeatureEnabledQuery(featureName);

	if (isLoading) {
		return (
			<div className="flex flex-col items-center text-center justify-center w-full gap-y-4 ">
				<div className="text-center">
					<Loader2 className="w-16 h-16 animate-spin text-primary mb-4" />
				</div>
			</div>
		);
	}

	if (!data?.enabled) {
		if (redirectTo) {
			return <Navigate to={redirectTo} replace />;
		}
		return fallback ?? null;
	}

	return <>{children}</>;
};
