import { useGetUserPercentileQuery } from "@/common/services/user.api";
import Typography from "@/common/components/design/texts/typography";
import { Skeleton } from "@/common/components/ui/skeleton";
import { errorReporting } from "@/common/utils/error-reporting";

export function UserPercentile() {
	const {
		data: percentileData,
		isLoading,
		error,
	} = useGetUserPercentileQuery();

	if (isLoading) {
		return (
			<div className="flex-shrink-0">
				<Skeleton className="h-7 w-20 rounded-none" />
			</div>
		);
	}

	if (error) {
		errorReporting.captureError(error, {
			component: "UserPercentile",
			endpoint: "user/percentile",
		});
		return null;
	}

	if (!percentileData?.data.currentPercentile) {
		errorReporting.captureMessage("No percentile data available", "warning", {
			component: "UserPercentile",
		});
		return null;
	}

	const percentileText = `TOP ${percentileData.data.currentPercentile}%`;

	return (
		<div className="flex-shrink-0 bg-[#FFFFFF]/10 px-3 py-1 flex items-center">
			<Typography variant="body" className="font-kanit font-normal text-xs text-white">
				{percentileText}
			</Typography>
		</div>
	);
}
