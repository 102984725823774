import { createApi } from "@reduxjs/toolkit/query/react";
import {
	ClaimFightIdRequestDto,
	ClaimFightIdResponseDto,
} from "../types/entities/user/dto/fightid.dto";
import { interceptor } from "./interceptor/query.interceptor";
import { UserDataResponseDto } from "../types/entities/user/dto/user.dto";
import { UpdateNotificationSettingsDto } from "../types/entities/user/dto/notification-settings.dto";
import { PercentileDetailsResponseDto } from "../types/entities/user/dto/percentile.dto";

export const userApi = createApi({
	reducerPath: "userApi",
	baseQuery: interceptor,
	tagTypes: ["User", "UserPercentile"],
	endpoints: (builder) => ({
		claimFightId: builder.mutation<
			ClaimFightIdResponseDto,
			ClaimFightIdRequestDto
		>({
			query: (body) => ({
				url: `user/claim-fightid`,
				method: "POST",
				body,
			}),
			invalidatesTags: ["User"],
		}),
		checkFightIdAvailability: builder.query<ClaimFightIdResponseDto, string>({
			query: (fightId) => ({
				url: `user/check-fightid-availability/${fightId}`,
				method: "GET",
			}),
		}),
		getUserData: builder.query<UserDataResponseDto, void>({
			query: () => ({
				url: `user`,
				method: "GET",
			}),
			providesTags: ["User"],
		}),
		setNotificationConfig: builder.mutation<
			any,
			{ userId: string; message: string }
		>({
			query: (body) => ({
				url: `user/notification-config/${body.userId}`,
				method: "POST",
				body,
			}),
		}),
		updateNotificationSettings: builder.mutation<
			UserDataResponseDto,
			UpdateNotificationSettingsDto
		>({
			query: (body) => ({
				url: `user/notification-settings`,
				method: "PATCH",
				body,
			}),
			async onQueryStarted({ setting, enabled }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					userApi.util.updateQueryData("getUserData", undefined, (draft) => {
						if (!draft) return;
						draft.data.settings[setting] = enabled;
					})
				);

				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
			invalidatesTags: ["User"],
		}),
		getUserPercentile: builder.query<PercentileDetailsResponseDto, void>({
			query: () => ({
				url: `user/percentile`,
				method: "GET",
			}),
			providesTags: ["UserPercentile"],
		}),
	}),
});

export const {
	useClaimFightIdMutation,
	useLazyCheckFightIdAvailabilityQuery,
	useSetNotificationConfigMutation,
	useGetUserDataQuery,
	useLazyGetUserDataQuery,
	useUpdateNotificationSettingsMutation,
	useGetUserPercentileQuery,
} = userApi;
