import { Button } from "@/common/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/common/components/ui/dialog";
import { userApi } from "@/common/services/user.api";
import logger from "@/common/utils/logger";
import { CheckCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

export function CampJoinDialog() {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const [open, setOpen] = useState(false);
	const [hasJoined, setHasJoined] = useState(false);
	const [fighterName, setFighterName] = useState("");

	// Check for joinedCamp query param
	useEffect(() => {
		const joinedCamp = searchParams.get("joinedCamp");
		if (joinedCamp) {
			setFighterName(joinedCamp);
			setOpen(true);
			setHasJoined(true);
		}
	}, [searchParams]);

	// Handle the quest refetch when camp is joined
	useEffect(() => {
		if (hasJoined) {
			// Small delay to ensure the query is ready
			const timer = setTimeout(() => {
				try {
					// Invalidate user data to refresh camp member status
					dispatch(userApi.util.invalidateTags(["User"]));
				} catch (error) {
					logger.error(error, "Error fetching user camp data");
				}
			}, 100);

			return () => clearTimeout(timer);
		}
	}, [hasJoined, dispatch]);

	// Handle dialog close
	const handleClose = () => {
		setOpen(false);
		// Remove the joinedCamp parameter from the URL
		searchParams.delete("joinedCamp");
		setSearchParams(searchParams);
	};

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="max-w-sm rounded-lg lg:max-w-md">
				<DialogHeader>
					<div className="flex justify-center mb-4">
						<div className="bg-green-500 rounded-full p-4">
							<CheckCircle className="h-10 w-10 text-white" />
						</div>
					</div>
					<DialogTitle className="text-center text-xl uppercase">
						WELCOME TO {fighterName}'S CAMP!
					</DialogTitle>
					<DialogDescription className="text-center">
						You're now part of the team! Get ready to help {fighterName} conquer
						the octagon. Complete quests, earn rewards, and track your progress
						on the Camps Leaderboard. Let's fight for glory!
					</DialogDescription>
				</DialogHeader>
				<DialogFooter className="sm:justify-center">
					<Button onClick={handleClose} className="w-full sm:w-auto">
						Let's Go!
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
