import {
	Sidebar,
	SidebarContent,
	SidebarFooter,
	SidebarGroup,
	SidebarGroupContent,
	SidebarGroupLabel,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	SidebarSeparator,
	useSidebar,
} from "@/common/components/ui/sidebar";
import { QuestId } from "@/common/constants/quest-ids";
import { useQuestsStore } from "@/common/hooks/use-quests-store";
import { useUserStore } from "@/common/hooks/use-user-store";
import { Routes } from "@/common/types/routes";
import { Settings } from "lucide-react";
import { NavLink, useLocation } from "react-router-dom";
import Typography from "../../design/texts/typography";
import DiscordLogo from "../../icons/discord";
import TelegramLogo from "../../icons/telegram";
import XLogo from "../../icons/x";
import { NavLogout } from "./nav/nav-logout";
import { NavWallet } from "./nav/nav-wallet";

const getAppLinks = (_isQuestEnabled: (questId: QuestId) => boolean) => [
	{
		title: "Settings",
		url: Routes.Notifications,
		icon: <Settings />,
		enabled: true,
		// enabled: isQuestEnabled(QUEST_IDS.MODULE_SETTINGS),
	},
];

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
	const location = useLocation();
	const { isMobile, setOpenMobile } = useSidebar();

	const userEmail = useUserStore((state) => state.user.email);
	const getFightUsername = useUserStore((store) => store.getFightUsername);
	const isQuestEnabled = useQuestsStore((state) => state.isQuestEnabled);

	// Generate the app links using the quest store
	const appLinks = getAppLinks(isQuestEnabled);

	// Function to close mobile sidebar on navigation
	const handleNavClick = () => {
		if (isMobile) {
			setOpenMobile(false);
		}
	};

	return (
		<Sidebar {...props}>
			<SidebarHeader>
				<SidebarGroup>
					<Typography
						variant="title-small"
						className="uppercase text-xl font-bold"
					>
						{getFightUsername()}
					</Typography>
					<Typography variant="body" className="text-xs text-white/70">
						{userEmail}
					</Typography>
				</SidebarGroup>
			</SidebarHeader>
			<SidebarContent className="[&_svg]:size-5">
				<SidebarSeparator className="mx-4" />
				<SidebarGroup>
					<SidebarGroupContent>
						<SidebarMenu>
							<NavWallet />
							{appLinks
								.filter((link) => link.enabled)
								.map((link) => (
									<SidebarMenuItem key={link.title}>
										<SidebarMenuButton
											asChild
											isActive={location.pathname === link.url}
										>
											<NavLink
												to={link.url}
												className="flex gap-2"
												onClick={handleNavClick}
											>
												{link.icon} {link.title}
											</NavLink>
										</SidebarMenuButton>
									</SidebarMenuItem>
								))}
						</SidebarMenu>
					</SidebarGroupContent>
				</SidebarGroup>

				<SidebarSeparator className="mx-4" />

				<SidebarGroup>
					<SidebarGroupContent>
						<SidebarMenu>
							<NavLogout />
						</SidebarMenu>
					</SidebarGroupContent>
				</SidebarGroup>
			</SidebarContent>

			{/* Mobile Footer Content */}
			<SidebarFooter>
				{isMobile && (
					<div>
						{/* Spacer to push footer content to bottom */}
						<div className="flex-grow" />

						{/* Social icons for mobile users */}
						<SidebarGroup>
							<SidebarGroupLabel className="text-xs text-white/70 font-normal">
								Social
							</SidebarGroupLabel>
							<SidebarGroupContent>
								<div className="flex gap-4 px-3 py-2">
									<NavLink
										to="https://x.com/MakeFight_Great/"
										target="_blank"
										className="text-sidebar-foreground hover:text-sidebar-foreground/80"
										onClick={handleNavClick}
									>
										<XLogo className="w-7 h-7" />
										<span className="sr-only">X</span>
									</NavLink>
									<NavLink
										to="mailto:support@fight.id"
										className="text-sidebar-foreground hover:text-sidebar-foreground/80"
										onClick={handleNavClick}
									>
										<TelegramLogo className="w-7 h-7" />
										<span className="sr-only">Telegram</span>
									</NavLink>
									<NavLink
										to="https://t.co/ob8JFeqAeb"
										target="_blank"
										className="text-sidebar-foreground hover:text-sidebar-foreground/80"
										onClick={handleNavClick}
									>
										<DiscordLogo className="w-7 h-7" />
										<span className="sr-only">Discord</span>
									</NavLink>
								</div>
							</SidebarGroupContent>
						</SidebarGroup>

						{/* Links for mobile users */}
						<SidebarGroup className="mb-2">
							<SidebarGroupLabel className="text-xs text-white/70 font-normal">
								Company
							</SidebarGroupLabel>
							<SidebarGroupContent>
								<SidebarMenu className="gap-0">
									<SidebarMenuItem>
										<SidebarMenuButton asChild>
											<NavLink
												to="#"
												className="text-sidebar-foreground hover:text-sidebar-foreground/80 font-semibold text-xs"
												onClick={handleNavClick}
											>
												HELP
											</NavLink>
										</SidebarMenuButton>
									</SidebarMenuItem>
									<SidebarMenuItem>
										<SidebarMenuButton asChild>
											<NavLink
												to="/FightFi Claim Terms and Conditions.pdf"
												className="text-sidebar-foreground hover:text-sidebar-foreground/80 font-semibold text-xs"
												target="_blank"
												download
												onClick={handleNavClick}
											>
												TERMS OF USE
											</NavLink>
										</SidebarMenuButton>
									</SidebarMenuItem>
								</SidebarMenu>
							</SidebarGroupContent>
						</SidebarGroup>

						{/* Copyright for mobile users */}
						<div className="px-4 py-2 text-xs text-sidebar-foreground/70 mb-4">
							<NavLink
								to="https://fightfi.xyz"
								target="_blank"
								onClick={handleNavClick}
							>
								© FightFi Foundation.
							</NavLink>
						</div>
					</div>
				)}
			</SidebarFooter>
		</Sidebar>
	);
}
