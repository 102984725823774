import * as Sentry from "@sentry/react";
import logger from "./logger";

interface ErrorContext {
	component: string;
	endpoint?: string;
	extra?: Record<string, unknown>;
}

export const errorReporting = {
	/**
	 * Report an error with both Sentry and local logger
	 */
	captureError: (error: unknown, context: ErrorContext) => {
		// Log to Sentry
		Sentry.captureException(error, {
			tags: {
				component: context.component,
				...(context.endpoint && { endpoint: context.endpoint }),
			},
			...(context.extra && { extra: context.extra }),
		});

		// Log locally in development
		logger.error(error, context.component);
	},

	/**
	 * Report a message with custom level
	 */
	captureMessage: (
		message: string,
		level: Sentry.SeverityLevel,
		context: ErrorContext
	) => {
		// Log to Sentry
		Sentry.captureMessage(message, {
			level,
			tags: {
				component: context.component,
				...(context.endpoint && { endpoint: context.endpoint }),
			},
			...(context.extra && { extra: context.extra }),
		});

		// Log locally in development
		switch (level) {
			case "error":
				logger.error(message, context.component);
				break;
			case "warning":
				logger.warn(message, context.component);
				break;
			case "info":
				logger.info(message, context.component);
				break;
			case "debug":
				logger.debug(message, context.component);
				break;
			default:
				logger.info(message, context.component);
		}
	},
};
