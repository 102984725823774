import { LogOut } from "lucide-react";
import { useAuth0 } from "@auth0/auth0-react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useUserStore } from "@/common/hooks/use-user-store";
import { useLoadingStore } from "@/modules/login/hooks/use-loading";
import {
	SidebarMenuButton,
	SidebarMenuItem,
	useSidebar,
} from "@/common/components/ui/sidebar";
import { useDispatch } from "react-redux";
import { userApi } from "@/common/services/user.api";

export function NavLogout() {
	const { disconnect } = useWallet();
	const { logout } = useAuth0();
	const clearUser = useUserStore((store) => store.clear);
	const initializeData = useLoadingStore((store) => store.initializeData);
	const { isMobile, setOpenMobile } = useSidebar();
	const dispatch = useDispatch();

	const handleLogout = () => {
		// Close mobile sidebar if on mobile
		if (isMobile) {
			setOpenMobile(false);
		}

		// Clear Aptos Connect session storage to force account selection on next login
		if (typeof window !== "undefined") {
			// Clear Aptos Connect session data
			Object.keys(sessionStorage).forEach((key) => {
				if (key.startsWith("aptosConnect:")) {
					sessionStorage.removeItem(key);
				}
			});

			// Clear Google auth session cookies
			document.cookie.split(";").forEach((cookie) => {
				const [name] = cookie.trim().split("=");
				if (
					name.includes("g_") ||
					name.includes("GAPS") ||
					name.includes("LSID")
				) {
					document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
				}
			});
		}

		logout({
			openUrl: false,
		});
		disconnect();
		clearUser();
		initializeData();
		// clear all RTK data
		dispatch(userApi.util.resetApiState());
	};

	return (
		<SidebarMenuItem>
			<SidebarMenuButton onClick={handleLogout} className="flex gap-2">
				<LogOut className="h-4 w-4" /> Log out
			</SidebarMenuButton>
		</SidebarMenuItem>
	);
}

export default NavLogout;
