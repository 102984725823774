import { useEffect, useState, useCallback } from "react";
import { toast } from "sonner";
import { useUserStore } from "./use-user-store";
import { useUpdateNotificationSettingsMutation } from "@/common/services/user.api";

interface BeforeInstallPromptEvent extends Event {
	prompt: () => Promise<void>;
	userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
}

const PWA_STATUS_KEY = "pwa-status";
const UPDATE_INTERVAL = 7 * 24 * 60 * 60 * 1000; // 7 days

export function usePWAInstallation() {
	const [deferredPrompt, setDeferredPrompt] =
		useState<BeforeInstallPromptEvent | null>(null);
	const [updateSettings] = useUpdateNotificationSettingsMutation();
	const isInstalled = useUserStore((state) => state.user.settings.pwaInstalled);

	const checkInstallation = useCallback(() => {
		const isStandalone =
			window.matchMedia("(display-mode: standalone)").matches ||
			(window.navigator as any).standalone ||
			document.referrer.includes("android-app://");

		// Only update if the user is using PWA
		if (isStandalone) {
			const lastUpdate = localStorage.getItem(PWA_STATUS_KEY);
			const shouldUpdate =
				!lastUpdate || Date.now() - parseInt(lastUpdate, 10) > UPDATE_INTERVAL;

			if (shouldUpdate) {
				updateSettings({
					setting: "pwaInstalled",
					enabled: true,
				})
					.then(() => {
						localStorage.setItem(PWA_STATUS_KEY, Date.now().toString());
					})
					.catch((error) => {
						console.error("Failed to update PWA status:", error);
					});
			}
		}

		return isStandalone;
	}, [updateSettings]);

	useEffect(() => {
		// Check initial installation status
		checkInstallation();

		// Listen for display mode changes
		const mediaQuery = window.matchMedia("(display-mode: standalone)");
		const handleChange = (e: MediaQueryListEvent) => {
			if (e.matches) {
				// Only check when changing TO standalone mode
				checkInstallation();
			}
		};

		mediaQuery.addEventListener("change", handleChange);

		// Listen for beforeinstallprompt event
		const handleBeforeInstallPrompt = (e: Event) => {
			e.preventDefault();
			setDeferredPrompt(e as BeforeInstallPromptEvent);
		};

		window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

		// Listen for appinstalled event
		const handleAppInstalled = () => {
			checkInstallation(); // This will now only update if actually running as PWA
			setDeferredPrompt(null);
			toast.success("App installed successfully!");
		};

		window.addEventListener("appinstalled", handleAppInstalled);

		return () => {
			mediaQuery.removeEventListener("change", handleChange);
			window.removeEventListener(
				"beforeinstallprompt",
				handleBeforeInstallPrompt
			);
			window.removeEventListener("appinstalled", handleAppInstalled);
		};
	}, [checkInstallation]);

	const promptInstall = async () => {
		if (!deferredPrompt) {
			toast.error("Installation prompt not available");
			return;
		}

		try {
			await deferredPrompt.prompt();
			const choiceResult = await deferredPrompt.userChoice;

			if (choiceResult.outcome === "accepted") {
				setDeferredPrompt(null);
			}
		} catch (error) {
			console.error("Error during installation:", error);
			toast.error("Failed to install the app");
		}
	};

	return {
		isInstalled,
		canInstall: !!deferredPrompt,
		promptInstall,
	};
}
