import { createApi } from "@reduxjs/toolkit/query/react";
import { interceptor } from "./interceptor/query.interceptor";
import { FeatureFlagResponseDto } from "../types/entities/feature-flags/dto/feature-flags.dto";

export const featureFlagsApi = createApi({
	reducerPath: "featureFlagsApi",
	baseQuery: interceptor,
	tagTypes: ["FeatureFlag"],
	endpoints: (builder) => ({
		getIsFeatureEnabled: builder.query<FeatureFlagResponseDto, string>({
			query: (featureName) => ({
				url: `feature-flags/${featureName}/status`,
				method: "GET",
			}),
			providesTags: (_result, _error, featureName) => [
				{ type: "FeatureFlag", id: featureName },
			],
		}),
	}),
});

export const { useGetIsFeatureEnabledQuery } = featureFlagsApi;
