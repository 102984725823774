// src/push-notifications.ts
import { urlB64ToUint8Array } from "@/common/lib/utils";
import { useSetNotificationConfigMutation } from "@/common/services/user.api";
import { publicVapidKey } from "@/environment";
import { toast } from "sonner";
import { useState, useEffect } from "react";
import logger from "./common/utils/logger";

interface PushNotificationState {
	permission: NotificationPermission;
	isSubscribed: boolean;
	subscription: PushSubscription | null;
}

export function usePushNotifications(userId: string, updateUser: Function) {
	const [setConfig] = useSetNotificationConfigMutation();
	const [state, setState] = useState<PushNotificationState>({
		permission: "default",
		isSubscribed: false,
		subscription: null,
	});

	// Add dependency on userId
	useEffect(() => {
		async function initializeState() {
			// Only proceed if we have a userId
			if (!userId) return;

			const permission = await checkNotificationPermission();
			const registration = await navigator.serviceWorker.getRegistration();
			const subscription = registration
				? await registration.pushManager.getSubscription()
				: null;

			setState({
				permission,
				isSubscribed: !!subscription,
				subscription,
			});
		}

		initializeState();
	}, [userId]); // Add userId as dependency

	const subscribe = async () => {
		const permission = await requestNotificationPermission();
		if (permission === "granted") {
			const subscription = await subscribeToPushNotifications(
				userId,
				updateUser,
				setConfig
			);
			setState((prev) => ({
				...prev,
				permission,
				isSubscribed: !!subscription,
				subscription,
			}));
		}
	};

	const unsubscribe = async () => {
		const success = await unsubscribeFromPushNotifications(
			userId,
			updateUser,
			setConfig
		);
		if (success) {
			setState((prev) => ({
				...prev,
				isSubscribed: false,
				subscription: null,
			}));
		}
	};

	return {
		...state,
		subscribe,
		unsubscribe,
		isReady: !!userId,
	};
}

async function checkNotificationPermission(): Promise<NotificationPermission> {
	if (!("Notification" in window)) {
		toast.error("This browser does not support notifications.");
		return "denied";
	}
	return Notification.permission;
}

async function requestNotificationPermission(): Promise<NotificationPermission> {
	try {
		const permission = await Notification.requestPermission();
		if (permission !== "granted") {
			toast.info("Please go to settings and enable notifications.");
		}
		return permission;
	} catch (error) {
		toast.error("Error requesting notification permission");
		return "denied";
	}
}

async function subscribeToPushNotifications(
	userId: string,
	updateUser: Function,
	setConfig: Function
) {
	if (!("serviceWorker" in navigator)) {
		toast.error("Service workers are not supported in this browser");
		return null;
	}

	try {
		const registration = await navigator.serviceWorker.ready;

		let subscription = await registration.pushManager.getSubscription();

		if (!subscription) {
			const applicationServerKey = urlB64ToUint8Array(publicVapidKey);
			subscription = await registration.pushManager.subscribe({
				applicationServerKey,
				userVisibleOnly: true,
			});
		}

		// Use RTK Query mutation
		await setConfig({
			userId: userId,
			message: JSON.stringify(subscription),
		});

		// Update local state
		updateUser(JSON.stringify(subscription));

		return subscription;
	} catch (error) {
		logger.error(error, "Push Notifications");
		toast.error("Error subscribing to push notifications");
		return null;
	}
}

async function unsubscribeFromPushNotifications(
	userId: string,
	updateUser: Function,
	setConfig: Function
): Promise<boolean> {
	try {
		const registration = await navigator.serviceWorker.getRegistration();
		if (registration) {
			const subscription = await registration.pushManager.getSubscription();
			if (subscription) {
				await subscription.unsubscribe();
			}
		}

		// Use RTK Query mutation
		await setConfig({
			userId: userId,
			message: "",
		});

		// Update local state
		updateUser("");

		return true;
	} catch (error) {
		toast.error("Error unsubscribing from push notifications");
		return false;
	}
}
