import { AuthWrapper } from "./auth/auth-wrapper-zustand";
import { UsernameGuard } from "./auth/username-guard";
import { PageBackground } from "./layout/backgrounds";
import Footer from "./shared/footer/footer";
import AuthHeader from "./shared/header/auth-header";
import NavFooter from "./shared/nav/nav-footer";
import { AppSidebar } from "./shared/sidebar/app-sidebar";
import { SidebarInset, SidebarProvider } from "./ui/sidebar";
import { useSyncUserData } from "../hooks/use-sync-user-data";
import { usePWAInstallation } from "../hooks/use-pwa-installation";

function Layout({ children }: { children: React.ReactNode }) {
	// Sync user data at layout level, RTK Query will handle cache invalidation
	useSyncUserData();

	// Track PWA installation status at layout level
	usePWAInstallation();

	return (
		<SidebarProvider>
			<AuthWrapper>
				<UsernameGuard>
					<SidebarInset className="">
						<PageBackground />
						<AuthHeader />
						<div className="flex flex-col min-h-[calc(100vh-64px)] w-full max-w-7xl mx-auto z-10">
							<main className="flex-1 w-full">
								<div className="container mx-auto p-4 sm:px-6 md:gap-8 md:pt-8 h-full pb-24 md:pb-4">
									{children}
								</div>
							</main>
							<Footer />
						</div>
						<NavFooter />
					</SidebarInset>
					<AppSidebar side="right" />
				</UsernameGuard>
			</AuthWrapper>
		</SidebarProvider>
	);
}

export default Layout;
