import Typography from "@/common/components/design/texts/typography";
import { Switch } from "@/common/components/ui/switch";
import { useUpdateNotificationSettingsMutation } from "@/common/services/user.api";
import { useUserStore } from "@/common/hooks/use-user-store";
import { toast } from "sonner";
import { usePushNotifications } from "@/push-notifications";

export default function NotificationPreferencesForm() {
	const user = useUserStore((state) => state.user);
	const updateNotification = useUserStore((state) => state.updateNotification);
	const [updateSettings, { isLoading }] =
		useUpdateNotificationSettingsMutation();
	const { subscribe, unsubscribe } = usePushNotifications(
		user.id,
		updateNotification
	);

	const handleSettingChange = async (
		setting: "pushNotificationsEnabled" | "emailNotificationsEnabled",
		enabled: boolean
	) => {
		let pushNotificationSuccess = true;
		let settingsUpdateSuccess = true;

		if (setting === "pushNotificationsEnabled") {
			try {
				if (enabled) {
					await subscribe();
				} else {
					await unsubscribe();
				}
			} catch (error) {
				pushNotificationSuccess = false;
				toast.error(
					enabled
						? "Failed to enable push notifications"
						: "Failed to disable push notifications"
				);
			}
		}

		try {
			await updateSettings({
				setting,
				enabled,
			}).unwrap();
		} catch (error) {
			settingsUpdateSuccess = false;
			toast.error("Failed to update notification settings");
		}

		if (pushNotificationSuccess && settingsUpdateSuccess) {
			toast.success("Settings updated successfully");
		} else if (
			setting === "pushNotificationsEnabled" &&
			!pushNotificationSuccess
		) {
			// If push notification failed, revert the settings
			try {
				await updateSettings({
					setting,
					enabled: !enabled,
				}).unwrap();
			} catch {
				// If reverting also fails, show a more serious error
				toast.error("Failed to sync notification settings. Please try again.");
			}
		}
	};

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-4">
				<div className="flex items-center justify-between">
					<div className="flex flex-col">
						<Typography
							variant="body"
							className="font-medium text-lg text-white"
						>
							Push Notifications
						</Typography>
					</div>
					<Switch
						className="data-[state=checked]:bg-terciary"
						disabled={isLoading}
						checked={user.settings.pushNotificationsEnabled ?? false}
						onCheckedChange={(enabled) =>
							handleSettingChange("pushNotificationsEnabled", enabled)
						}
					/>
				</div>

				<div className="flex items-center justify-between">
					<div className="flex flex-col">
						<Typography
							variant="body"
							className="font-medium text-lg text-white"
						>
							Get Email Updates
						</Typography>
					</div>
					<Switch
						className="data-[state=checked]:bg-terciary"
						disabled={isLoading}
						checked={user.settings.emailNotificationsEnabled ?? false}
						onCheckedChange={(enabled) =>
							handleSettingChange("emailNotificationsEnabled", enabled)
						}
					/>
				</div>
			</div>
		</div>
	);
}
